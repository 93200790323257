<template>
  <form @submit.prevent="updateDetail(deficiency,'cause')">
    <div class="row justify-content-between">
      <div class="col-12 text-right">
        <span class="mr-3"><b>Note</b> : The structure and description of Immediate Cause and Root Cause based on the DNV Marine Systematic Cause.</span>
        <template v-if="toEdit">
          <button class="e-btn e-btn-outlined-red ml-2" @click="cancelUpdate('cause')" type="button">Cancel</button>
          <button class="e-btn e-btn-blue ml-2" :class="pscHasChanges === false ? 'e-btn-blue-disabled' : ''" type="submit" :disabled="pscHasChanges === false">Save</button>
        </template>
        <template v-else>
          <button class="e-btn e-btn-green ml-2 px-3" @click="editCause" type="button"
                  v-if="isAllowed('psc.main.deficiency.overview.cause.edit') && isOwnVessel('pscReport')">Edit Detail</button>
        </template>
      </div>
    </div>
    <table class="w-100">
      <tr>
        <td colspan="2">
          <h6 class="font-weight-bold">Immediate Cause</h6>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">a) Substandard Act <sub class="lead e-text-red font-weight-bold">*</sub></td>
        <td>
          <template v-if="toEdit">
            <template v-if="!!deficiency.cause">
                <select class="form-control text-uppercase form-control-sm" v-model="deficiency.cause.immediate_cause_id">
                  <option :value="null">—— SELECT SUBSTANDARD ACT ——</option>
                  <option v-for="act in immediateActsMain" :key="act.id" :value="act.id">
                    {{ act.series + '_' }}
                    {{ act.name }}
                  </option>
                </select>
              </template>
              <template v-if="immediateActsSub.length">
                <template v-if="!!deficiency.cause">
                  <select  class="form-control form-control-sm mt-1 text-uppercase" v-model="deficiency.cause.immediate_cause_child_id">
                      <option :value="null">—— SELECT SUBSTANDARD CHILD ——</option>
                      <option v-for="act in immediateActsSub" :key="act.id" :value="act.id">
                        {{ act.series + '_' }}
                        {{ act.name }}
                      </option>
                    </select>
                </template>
              </template>
            </template>
          <span v-else>
            {{
              deficiency?.cause?.immediate_cause_id ? deficiency?.cause?.immediate_cause?.name?.toUpperCase() : ''
            }}
            {{ deficiency?.cause?.immediate_cause_child_id ? '/' : '' }}
            {{
              deficiency?.cause?.immediate_cause_child_id ? deficiency?.cause?.immediate_cause_child?.name?.toUpperCase() : ''
            }}

          </span>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">b) Substandard Condition</td>
        <td>
          <template v-if="toEdit">
            <select class="form-control form-control-sm text-uppercase" v-model="deficiency.cause.immediate_cause_sub_id">
              <option :value="null">—— SELECT SUBSTANDARD CONDITION ——</option>
              <option v-for="condition in immediateConditionsMain" :key="condition.id" :value="condition.id">
                {{ condition.series + '_' }}
                {{ condition.name }}
              </option>
            </select>
          </template>
          <span v-else>
             {{
              deficiency?.cause?.immediate_cause_sub_id ? deficiency?.cause?.immediate_cause_sub?.name.toUpperCase() : ''
            }}
          </span>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Cause Detail</td>
        <td>
          <vue-editor v-model="deficiency.cause.immediate_cause_detail" class="my-2" v-if="toEdit" :editor-toolbar="toolbar">
          </vue-editor>
          <div v-else class="pre-wrap-txt textarea-display-card my-2"
               v-html="deficiency?.cause?.immediate_cause_detail || ''"/>
        </td>
      </tr>
    </table>
    <hr/>
    <table class="w-100">
      <tr>
        <td colspan="2">
          <h6 class="font-weight-bold">Root Cause</h6>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">a) Root Cause <sub class="lead e-text-red font-weight-bold">*</sub></td>
        <td>
          <select v-model="deficiency.cause.root_cause_id" class="form-control form-control-sm text-uppercase" v-if="toEdit">
            <option :value="null">—— select root cause ——</option>
            <option v-for="factor in basicCauseMain" :key="factor.id" :value="factor.id">
              {{ factor.series + '_' }}
              {{ factor.name }}
            </option>
          </select>
          <span v-else>
            {{
              deficiency?.cause?.root_cause_id ? deficiency?.cause?.root_cause?.name?.toUpperCase() : ''
            }}
          </span>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">b) Factor of Root Cause</td>
        <td>
           <template v-if="!!deficiency.cause">
             <select v-model="deficiency.cause.root_cause_child_id" class="form-control form-control-sm text-uppercase"
                     v-if="toEdit">
               <option :value="null">—— select sub factors ——</option>
               <option v-for="factor in basicCauseSub" :key="factor.id" :value="factor.id">
                 {{ factor.series + '_' }}
                 {{ factor.name }}
               </option>
             </select>
             <span v-else>
              {{
                 deficiency?.cause?.root_cause_child_id ? deficiency?.cause?.root_cause_child?.name?.toUpperCase() : ''
               }}
            </span>
           </template>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Cause Detail</td>
        <td>
          <vue-editor v-model="deficiency.cause.root_cause_detail" class="my-2" v-if="toEdit" :editor-toolbar="toolbar">
          </vue-editor>
          <div v-else class="pre-wrap-txt textarea-display-card my-2"
               v-html="deficiency?.cause?.root_cause_detail || ''"/>
        </td>
      </tr>
    </table>
  </form>
</template>

<script>
import CauseMixin from "../../../../mixins/CauseMixin";
import {permissionMixin} from "@/mixins/permissionMixins";
import PscDeficiencyMixin from "../../../../mixins/PscDeficiencyMixin";
import ObjectMixin from "../../../../mixins/ObjectMixin";
export default {
  name: "PscDeficiencyCause",
  mixins:[CauseMixin,permissionMixin,PscDeficiencyMixin,ObjectMixin],
  props:{
    cause:{
      type:Object,
      default:null,
    }
  },
  data(){
    return{
      toolbar:[
        ['bold','italic','strike','underline'],
        [{'color':["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]}]
      ]

    }
  },
  methods:{
    async editCause(){
      this.editDetail();
      const params = {
        main_cause_id: this.deficiency.cause.root_cause_id
      }
      await this.getSubCauseKeys(params)
      this.basicCauseSub = this.subCauseKeys

      if (this.deficiency.cause.immediate_cause_id === 36) {
        const params = {
          main_cause_id: this.deficiency.cause.immediate_cause_id
        }

        await this.getSubCauseKeys(params)
        this.immediateActsSub = this.subCauseKeys
      } else {
        this.immediateActsSub = {}
      }
    },
  },
  watch:{
    'deficiency.cause.immediate_cause_id': async function () {
      if (this.deficiency.cause.immediate_cause_id === 36) {
        const params = {
          main_cause_id: this.deficiency.cause.immediate_cause_id
        }
        await this.getSubCauseKeys(params)
        this.immediateActsSub = this.subCauseKeys
      } else {
        this.deficiency.cause.immediate_cause_child_id=null
        this.immediateActsSub = {}
      }
    },
    'deficiency.cause.root_cause_id': async function() {
      const params = {
        main_cause_id: this.deficiency.cause.root_cause_id
      }
      await this.getSubCauseKeys(params)
      this.basicCauseSub = this.subCauseKeys
    }
  },
}
</script>

<style scoped>
::v-deep .pre-wrap-txt.textarea-display-card p {
  margin-bottom: 0 !important;
}
</style>
